<template>
  <div class="section">
    <div class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-10">
              <div class="box">
                <div class="columns is-multiline is-centered is-vcentered">
                  <div class="column is-6-desktop is-10-tablet">
                    <img class="logo_view" src="@/assets/logo_asiaibs.png" alt="Logo AsiaIBS">
                  </div>
                  <template v-if="!errorUrlToken">
                    <div class="column is-6-desktop is-10-tablet">
                      <h1 class="form_title">
                        Url Invalida
                      </h1>
                      <h2 class="subtitle has-text-centered">
                        Asegúrese de Acceder Mediante La Url Que Se Le Ha Enviado a Su Correo.
                      </h2>
                    </div>
                  </template>
                  <template v-else>
                    <div class="column is-6-desktop is-10-tablet">
                      <form
                        @submit.prevent="recoveryPassword"
                        class="columns is-multiline is-centered"
                      >
                        <div class="column is-12">
                          <h1 class="form_title">
                            Contraseña Nueva
                          </h1>
                        </div>
                        <div class="column is-8">
                          <div class="field">
                            <div class="control has-icons-left">
                              <input
                                ref="password"
                                class="input is-rounded"
                                type="password"
                                v-model="password"
                                name="password"
                                placeholder="Contraseña"
                                v-validate="'required'"
                              >
                              <span class="icon is-small is-left">
                                <img class="input_icon_pass" src="@/assets/icon/lock-icon.png" alt="password">
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="column is-8 has-text-centered">
                          <div class="field">
                            <div class="control has-icons-left">
                              <input
                                class="input is-rounded"
                                :class="{'is-danger': vverrors.has('password_confirmation')}"
                                type="password"
                                name="password_confirmation"
                                placeholder="Confirmar Contraseña"
                                data-vv-as="contraseña"
                                v-validate="'required|confirmed:password'"
                              >
                              <span class="icon is-small is-left">
                                <img class="input_icon_pass" src="@/assets/icon/lock-icon.png" alt="password">
                              </span>
                            </div>
                          </div>
                          <span v-show="vverrors.has('password_confirmation')" class="help is-danger">{{ vverrors.first('password_confirmation') }}</span>
                        </div>
                        <div class="column is-8">
                          <button
                            class="button button_ibs is-rounded is-fullwidth"
                            :class="{ 'is-loading': loading }"
                            type="submit"
                          >
                            Cambiar Contraseña
                          </button>
                        </div>
                        <div class="column is-10 has-text-centered">
                          <router-link class="form_forgot_text" to="/login">
                            Iniciar Sesión
                          </router-link>
                        </div>
                      </form>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'Recovery',
  data () {
    return {
      email: null,
      password: null,
      urlToken: this.$route.query.token,
      errorUrlToken: false,
      loading: false
    }
  },
  computed: {
    ...mapState({ authentication: state => state.authentication })
  },
  methods: {
    ...mapMutations(['setAuthenticationValue']),
    ...mapActions(['passwordRecovery']),
    async recoveryPassword () {
      if (await this.$validator.validateAll()) {
        if (this.urlToken) {
          this.loading = true
          const data = {
            token: this.urlToken,
            password: this.password
          }
          this.passwordRecovery(data)
            .then((resp) => {
              if (resp) {
                this.password = null
                this.$router.push({ name: 'login' })
              }
              this.loading = false
            })
        }
      }
    }
  },
  beforeMount () {
    if (this.urlToken) {
      this.errorUrlToken = true
    }
  }
}
</script>
<style scoped>
  .section {
    background-image: url('~@/assets/background/bg-access.png');
    background-position: center;
    background-size: cover;
  }
  .is-fullheight {
    min-height: calc(100vh - 6rem);
  }
  .box {
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e1e5eb;
    background-color: #ffffff;
    padding: 9% 5%;
  }

  .form_title {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.56;
    text-align: center;
    color: #404254;
  }
  .input_icon {
    width: 16px;
  }
  .input_icon_pass {
    width: 12px;
  }

  .form_forgot_text {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: #404254;
  }
  .form_forgot_text:hover {
    color: #33343d;
  }

  .logo_view {
    max-width: 350px;
    display: block;
    margin: auto;
  }
</style>
